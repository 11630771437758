import styled from 'styled-components';
import { Form as _Form, Input } from '../../../form';
import { Card, Card as DefCard } from '../../../card';
import { colors, fonts } from '../../../../styles/constants';

export const Form = styled.form`
  ${_Form};
  max-width: 500px;
  span{
    ${Card};
    font-size: ${fonts.fs__secondary};
    background-color: ${colors.clr_r};
    font-weight: bold;
    padding: 0.2em .5em;
    margin: auto;
}
  .contact_input {
    ${Input};
  }
  
  .contact_button {
    all: unset;
    background-color: ${colors.clr_b};
    padding: .25em 2rem;
    font-size: 1rem;
    border-radius: .25em;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
    &:disabled{
      cursor: default;
    }
  }
  .contact_button, .contact_input, .contact_gdpr {
    opacity: 0.2;
  }

  .input_error {
      border-bottom: 3px solid ${colors.clr_r};
  }
  `;

export const TarifWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const TarifCheckbox = styled.label`
  ${DefCard};
  padding: 1em;
  cursor: pointer;

  input:checked + & {
    outline: #93B42F 2px solid;
  }

  :hover {
    outline: #1C98E0 1px solid;;
  }
`;