import { Form, TarifCheckbox, TarifWrapper } from './form.style';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import Data from '../../../../../content/objednat';
import FormData from '../../../../../content/general/form';
import gsap from 'gsap';
import { SpeedParams } from '../../../../animation/speed';
import { useEffect, useLayoutEffect, useState, useRef } from 'react';

const { tarifs } = Data;
const { name, tel, email, address, obec, psc } = FormData;
const FormObject = {
    name,
    tel,
    email,
    address,
    obec,
    psc,
};

export default ({ path }) => {
    const Ref = useRef();
    const ref = gsap.utils.selector(Ref);
    const [anim, setAnim] = useState<gsap.core.Timeline>();

    const lastPath = path[path.length -1]
    console.log(lastPath)
    const [selected, setSelected] = React.useState(lastPath != "objednat" ? lastPath : '');

    const onCheck = (link) => {
        setSelected(link);
    };

    const encode = (data) => {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    };

    const OnSubmit = (data) => {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'novaObjednavka', ...data }),
        })
            .then((res) => {
                if(res.ok){
                    alert("Vaše žádost byla přijata")
                }
                else {
                    alert("Něco se pokazilo")
                }
            })
            .catch((error) => alert(error));
    };

    useLayoutEffect(() => {
        setAnim(
            gsap.timeline({ paused: true }).to(
                ref('.contact_input, .contact_button, .contact_gdpr'),
                {
                    opacity: 1,
                    stagger: SpeedParams.stagger,
                    duration: SpeedParams.duration,
                },
                '<'
            )
        );
    }, []);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        setValue('tarif', selected);
        if (anim && selected != '') anim.play();

        console.log(watch());
    }, [selected, watch('tarif')]);

    return (
        <Form
            onSubmit={handleSubmit((data) => {
                OnSubmit(data);
            })}
            className="form"
            data-netlify="true"
            netlify-honeypot="bot-field"
            name="novaObjednavka"
            ref={Ref}
        >
            <label
                htmlFor="bot-field"
                style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    clip: 'rect(0 0 0 0)',
                    height: '1px',
                    width: '1px',
                    margin: '-1px',
                    padding: '0',
                    border: '0',
                    display: 'none',
                }}
            >
                Don’t fill this out if you're human:
                <input tabIndex={-1} name="bot-field" {...register('bot-field')} />
            </label>
            <TarifWrapper>
                <input
                    {...register('tarif', { required: true })}
                    hidden
                    name="tarif"
                    checked={tarifs[0].link === selected}
                    onChange={() => {
                        onCheck(tarifs[0].link);
                    }}
                    value={tarifs[0].link}
                    id="0"
                    type="radio"
                />
                <TarifCheckbox className="checkbox" htmlFor="0">
                    {tarifs[0].heading}
                </TarifCheckbox>
                <input
                    {...register('tarif', { required: true })}
                    hidden
                    name="tarif"
                    checked={tarifs[1].link === selected}
                    onChange={() => {
                        onCheck(tarifs[1].link);
                    }}
                    value={tarifs[1].link}
                    id="1"
                    type="radio"
                />
                <TarifCheckbox className="checkbox" htmlFor="1">
                    {tarifs[1].heading}
                </TarifCheckbox>
                <input
                    {...register('tarif', { required: true })}
                    hidden
                    name="tarif"
                    checked={tarifs[2].link === selected}
                    onChange={() => {
                        onCheck(tarifs[2].link);
                    }}
                    value={tarifs[2].link}
                    id="2"
                    type="radio"
                />
                <TarifCheckbox className="checkbox" htmlFor="2">
                    {tarifs[2].heading}
                </TarifCheckbox>
            </TarifWrapper>
            {Object.keys(FormObject).map((key, index) => (
                <React.Fragment key={index + key}>
                    <label hidden htmlFor="name">
                        {FormObject[key]}
                    </label>
                    <input
                        {...register(key as 'address' | 'tarif' | 'name' | 'tel' | 'email' | 'obec' | 'psc', { required: true })}
                        disabled={selected.length === 0}
                        className={`contact_input ${errors[key] ? 'input_error' : ''}`}
                        id={key}
                        name={key}
                        type={key}
                        placeholder={FormObject[key]}
                    />
                </React.Fragment>
            ))}
            {Object.keys(errors).length !== 0 && <span>{FormData.required}</span>}
            <p className="contact_gdpr">{FormData.gdpr}</p>
            <button disabled={selected.length === 0} className="contact_button" type="submit">
                {FormData.button}
            </button>
        </Form>
    );
};
