import styled from "styled-components"
import { fonts } from "../../../../styles/constants"
import { Card } from "../../../card"

   export const Heading = styled.h2`
    grid-column: span 3;
    position: relative;
    span {
        position: absolute;
        left: 0;
        right: 0;
        top: -0.5em;
        font-size: calc(${fonts.fs__default} * 6);
        opacity: 0.07;
    }
`
export const _Card = styled.div`
    ${Card};
    max-width: 500px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2rem 1rem;
    margin: auto;

    a {
        align-self: flex-end;
        margin-top: auto;
    }

    @media (max-width: 1100px) {
        min-height: 260px;
    }
`
