// @ts-ignore
import React, { useLayoutEffect, useRef} from 'react'
import { _Card, Heading } from './numbercard.style'
import {Props} from './numbercard.props'
import {gsap} from 'gsap'
import { HeadingAnimation } from '../../../../animation/heading'
import {ScrollParams} from '../../../../animation/scroll'
import Section from '../..'
import { SpeedParams } from '../../../../animation/speed'

export const NumberCard = ({id, number, heading, content, nocard}: Props) => {
    const Ref = useRef()
    const ref = gsap.utils.selector(Ref)

    useLayoutEffect(() => {
        gsap.timeline({scrollTrigger: {trigger: Ref.current, ...ScrollParams}})
            .from(ref('.heading'), HeadingAnimation.fromVars)
            .from(ref(".content, .card"),
                {
                    x: -50,
                    opacity: 0,
                    stagger: SpeedParams.stagger,
                    duration: SpeedParams.duration
                }, "<"
            )
    })


    return (
        <Section id={id} ref={Ref}>
            <>
                <Heading className="heading" id={id}><span>{number}</span>{heading}</Heading>
                {nocard ?
                <div className="content">
                {content}
                </div>
                : 
                    <_Card className="card">
                        {content}
                    </_Card> }
            </>
        </Section>
    )
}
