import * as React from 'react';
import { SectionFaq, SectionHero } from '../../components/sections/general';
import Layout from '../../layouts';
import { NumberCard } from '../../components/sections/sluzby/objednat';
import Seo from '../../components/sections/general/seo';
import Data from '../../../content/objednat';
import Form from '../../components/sections/sluzby/objednat/form';

const { seo, sections } = Data;



// markup
export default ({location}) => {

    console.log(location);
    const path =  location.pathname.split("/");
   

    sections.NumberedCards[0].content = <Form path={path}/>;

    return (
        <Layout>
            <Seo {...seo} />
            <SectionHero heading='Internet ve třech krocích pro Vás i Vaši firmu' />
            {sections.NumberedCards.map(({id,heading, content, background}, index)=>(
                <NumberCard content={content} heading={heading} number={index + 1} id={id} nocard={background} key={index+heading} />
            ))}
            <SectionFaq questions={sections.faq} />

        </Layout>
    );
}