import * as React from 'react';
import Button from '../src/components/button';
import Routes from './routes.json'
import faq from './general/faq';

export default {
    seo: {
        title: 'Objednat',
        description: 'Rozsáhlá internetová vysokorychlostní síť na míru s rychlostí až 1000mbps se skvělou podporou, neustálým servisem, chytrou televizí s možností záznamu až dvou týdnů.'
    },
    tarifs: [
        {
            id:"domacnost",
            heading: "Internet pro domácnost",
            link: "domacnost",
            cost: 249,
            speed: "20",
            connection: "5G",
            tv: true
        },
        {
            id:"firma",
            heading: "Internet pro firmy",
            link: "firma",
            cost: 249,
            speed: "",
            connection: "5G, 60G",
            tv: false
        },
        {
            id:"bytovy-dum",
            heading: "Internet pro bytové domy",
            link: "bytovy-dum",
            cost: 249,
            speed: "",
            connection: "WiFi",
            tv: false
        },
    ],
    sections: {
        hero: {
            heading: 'Internet ve třech krocích pro Vás i Vaši firmu',
        },
        NumberedCards: [{
            id: 'vyberte-si',
            heading: 'Vyberte si tarif',
            background: true
        }, {
            id: 'ozveme-se',
            heading: 'Ozveme se',
            background: false,
            content: <p> Po vyplnění a odeslání formuláře budete do dvou pracovních dnů kontaktováni jedním z našich
                techniků, který se s Vámi domluví na datumu a času možné konzultace a případného připojení.</p>,
        },
            {
                id: 'surfujete',
                heading: 'Surfujete',
                background: false,
                content: <> <p>Už jsme Vás připojili, internet běží. </p>
                    <p>Nyní máte možnost se přihlásit do naší klientské sekce.</p>
                    <p>Klientská sekce Vám bude po celou dobu naší spolupráce sloužit jako nástroj pro kontrolu vašich
                        produktů a smluv</p>
                    <Button animation='translateY' size='sm' color='blue' href={Routes.klientska_sekce}>
                        Klientská sekce
                    </Button></>
            }
        ],
        faq: faq
    },
};
